import React from "react";
import { nanoid } from "nanoid";
import PricingCard from "./PricingCard";

const offersData = [
  {
    id: 0,
    titre: "Free",
    tarif: 0,
    utilisateurs: 3,
    designers: 2,
    cacheIntelligent: "Nuit",
    puissance: "XS",
    fonctionalites: [
      "Utilisateurs illimités",
      "Tableaux de bord illimités",
      "Intégration API (mode test)",
      "API de gestion",
      "Cloisonnement de vos clients (mode test)",
      "Portail personnalisé",
    ],
  },
  {
    id: 1,
    titre: "Starter",
    tarif: "189",
    utilisateurs: 10,
    designers: 2,
    cacheIntelligent: "Nuit - 1h",
    puissance: "S",
    fonctionalites: [
      "Utilisateurs illimités",
      "Tableaux de bord illimités",
      "Intégration API",
      "API de gestion",
      "Cloisonnement de vos clients",
      "Portail personnalisé",
      "Portail avec url personnalisée",
      "Onboarding avec un consultant",
    ],
  },
  {
    id: 2,
    titre: "Pro",
    tarif: "490",
    utilisateurs: 100,
    designers: 2,
    cacheIntelligent: "Nuit - 1h - 15min",
    puissance: "M",
    fonctionalites: [
      "Utilisateurs illimités",
      "Tableaux de bord illimités",
      "Intégration API",
      "API de gestion",
      "Cloisonnement de vos clients",
      "Portail personnalisé",
      "Portail avec url personnalisée",
      "Onboarding avec un consultant",
      "Support prioritaire",
    ],
  },
  {
    id: 3,
    titre: "Premium",
    tarif: "1 250",
    utilisateurs: 1000,
    designers: 5,
    cacheIntelligent: "Nuit - 1h - 15min",
    puissance: "L",
    fonctionalites: [
      "Utilisateurs illimités",
      "Tableaux de bord illimités",
      "Intégration API",
      "API de gestion",
      "Cloisonnement de vos clients",
      "Portail personnalisé",
      "Portail avec url personnalisée",
      "Onboarding avec un consultant",
      "Support prioritaire",
      "SSO",
      "1h d'accompagnement mensuel avec un consultant Biwee",
      "Accès en direct aux données (temps réel)",
    ],
  },
];

const PricingTable = () => {
  return (
    <div className="bg-blurry bg-blur bg-cover relative">
      <div className="w-full backdrop-blur pb-16 flex flex-col items-center tablet:flex-none font-barlow">
        <div className="w-full tablet:max-w-[1256px] relative flex flex-col items-center tablet:flex-none">
          <div className="text-lg pt-1 tablet:text-base tablet:absolute tablet:left-0 tablet:top-2 font-semibold tracking-wide  text-white">
            <a href="https://www.weenove.fr/">{"<"}- www.weenove.fr </a>
          </div>

          <div className="text-5xl font-bold my-8 text-white flex">
            <svg
              version="1.1"
              id="Biwee Icon"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 40 32.7"
              style={{ enableBackground: "new 0 0 40 32.7" }}
              xmlSpace="preserve"
              fill="white"
              className="h-12 pr-2"
            >
              <g>
                <path
                  className="st0"
                  d="M30.9,14.8c-4.3-0.2-7.7-1.2-8.2-6.8c-0.5-5.3-4.8-7.6-9-7.6c-4.9,0-8.8,3.9-8.8,8.8c0,4.9,3.2,8.3,8.8,8.8
		c5.2,0.4,7.8,3.9,8.6,7.1c1.1,4.4,5.2,7.3,8.7,7.3c4.9,0,9.1-3.9,8.8-8.8C39.4,19.2,36.6,15,30.9,14.8z"
                />
                <circle className="st1" cx="5.4" cy="27.3" r="5.1" />
              </g>
            </svg>
            <h1>Tarifs Biwee</h1>
          </div>

          <ul className="w-full tablet:w-auto flex flex-col tablet:flex-row gap-16 tablet:gap-8 items-center tablet:items-start">
            {offersData.map((offer) => (
              <li key={nanoid()} className="w-full px-8 tablet:px-0">
                <PricingCard {...offer} />
              </li>
            ))}
          </ul>
          <div className="tablet:absolute tablet:mt-6 bottom-8 left-0 mt-12 mb-12 pt-2 pb-3 font-semibold bg-white text-2xl rounded-xl tablet:text-base px-8 tablet:px-0 tablet:w-[290px] flex justify-center items-baseline">
            <span>Designer supplémentaire</span>
            <span className="text-4xl tablet:text-2xl pl-3">30</span>
            <span className="text-lg tablet:text-xs">€ /mois</span>
          </div>
        </div>
      </div>
      <div className="custom-shape-divider-bottom-1660663403">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 0L0 0 598.97 114.72 1200 0z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default PricingTable;
