import React from "react";
import PricingTable from "../components/PricingTable";
import Faq from "../components/Faq";

const PricingPage = () => {
  return (
    <div className="text-slate-600 pb-48">
      <PricingTable />
      <Faq />
    </div>
  );
};

export default PricingPage;
