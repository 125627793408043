import React from "react";
import PricingPage from "./pages/PricingPage";

function App() {
  return (
    <div>
      <PricingPage />
    </div>
  );
}

export default App;
