import React from "react";
import { FaqQuestion } from "./FaqQuestion";

const Faq = () => {
  return (
    <section
      className="pt-12 bg-white"
      //   style="background-image: url('flex-ui-assets/elements/pattern-white.svg'); background-position: center;"
    >
      <div className="max-w-screen-tablet px-4 tablet:px-20 mx-auto">
        <div className="mx-auto text-center">
          <h2 className="mb-12 text-5xl leading-tight text-coolGray-900 font-bold tracking-tighter">
            Questions fréquentes
          </h2>
        </div>
        <div className="mx-auto">
          <FaqQuestion
            question="J’ai un besoin particulier, une demande sur mesure ?"
            expandedHeight="13rem"
          >
            Besoin d’une version On premise, d’un développement spécifique ou
            d’une puissance supérieure à L ? Nous avons la possibilité de nous
            adapter à de nombreuses demandes sur mesure, nous vous invitons à
            nous contacter pour en savoir plus.
          </FaqQuestion>
          <FaqQuestion
            question="Quelle est la différence entre utilisateurs et designers ?"
            expandedHeight="12rem"
          >
            Les designers peuvent créer des tableaux de bord, les modifier, et
            administrer les requêtes de données. Les utilisateurs peuvent
            consulter les tableaux de bord et interagir avec eux.
          </FaqQuestion>
          <FaqQuestion
            question="Comment fonctionne le système de cache intelligent ?"
            expandedHeight="51rem"
          >
            <p>
              Ce système permet d’obtenir des chargement de tableaux de bord en
              un temps record.
            </p>
            <p>
              Vos tableaux de bord sont connectés à vos données qui peuvent être
              de différentes formes : Bases de données, fichiers, services web,
              etc. A l’ouverture d’un tableau de bord, Biwee a besoin d’accéder
              à toutes ces données et d’appliquer les éventuelles manipulations
              que vous avez configurées sur Biwee. Ces données sont optimisées,
              compressées et chiffrées avant d’être traitées par le tableau de
              bord.
            </p>
            <p>
              Afin d’obtenir un temps de chargement record, nous avons mis en
              place un système de cache de donnée intelligent qui pré-calcule un
              cache de cette structure avant même que vous ayez ouvert votre eau
              de bord. Suivant l’offre que vous avez choisi, vous avez le choix
              entre les durées de cache suivantes : Nuit - 1h - 15min
            </p>
            <p>
              Par exemple, si vous choisissez 15min, Biwee vous assure que les
              données du tableaux de bord daterons au plus tard de 15 minutes.
              En pratique, à l’ouverture d’un tableau de bord, il y a deux cas
              de figure :
            </p>
            <span>
              <strong>Cas 1 :</strong> Le cache n’est pas expiré -{">"} le
              tableau de bord s’ouvre à partir du cache
            </span>
            <br />
            <span>
              <strong>Cas 2 :</strong> Le cache est expiré -{">"} le tableau de
              bord va se connecter en direct à vos sources de données
            </span>
            <p>
              Plus vous choisissez une durée de cache longue, plus vous avez de
              chances que le cache soit pré-rempli et que votre tableau de bord
              s’ouvre très rapidement.
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="Que signifie (mode test) associé à une fonctionnalité ?"
            expandedHeight="13rem"
          >
            L’intégration en API ainsi que le cloisonnement des sociétés est
            disponible dès la version gratuite de Biwee. Il y a aura cependant
            un filigrane “mode test” sur les tableaux de bord utilisant ces
            fonctionnalités dans l’abonnement gratuit de Biwee.
          </FaqQuestion>
        </div>
      </div>
    </section>
  );
};

export default Faq;
