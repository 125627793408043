import React, { useState } from "react";
import { nanoid } from "nanoid";
import { CgCheck } from "react-icons/cg";
import { BiInfoCircle } from "react-icons/bi";
import { FiInfo } from "react-icons/fi";
import { IoMdHelpCircle } from "react-icons/io";

const PricingItemText = (props) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div
      className="font-semibold w-full text-2xl tablet:text-lg tablet:tracking-tight flex items-baseline cursor-default hover:text-slate-500"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {props.text}
      {props.infoBulletText && (
        <div className="pl-1 relative">
          <BiInfoCircle size={16} />
          {/* {props.infoBulletText && isHovering && ( */}
          <div
            className={`absolute top-2 transition-transform ${
              isHovering ? "translate-x-0 scale-100" : "-translate-x-36 scale-0"
            } translate-y-[-50%] px-5 py-4 left-8 w-64 z-50 border-slate-400 border rounded-lg bg-white text-lg tablet:text-sm font-normal shadow-md text-slate-700`}
          >
            {props.infoBulletText}
          </div>
        </div>
      )}
    </div>
  );
};

const PricingCard = (props) => {
  let bgColor, txtColor;
  switch (props.id) {
    case 0:
      bgColor = "bg-cyan-500";
      txtColor = "text-cyan-500";
      break;
    case 1:
      bgColor = "bg-cyan-600";
      txtColor = "text-cyan-600";
      break;
    case 2:
      bgColor = "bg-yellow-500";
      txtColor = "text-yellow-500";
      break;
    case 3:
      bgColor = "bg-rose-400";
      txtColor = "text-rose-400";
      break;
    default:
      break;
  }

  return (
    <div className="relative">
      <div
        id="pricing-card"
        className="tablet:w-[290px] flex flex-col items-center"
      >
        <h2
          id="offer-title"
          className={`tracking-wide border-none text-5xl tablet:text-2xl font-semibold w-full flex items-center justify-center h-16 tablet:h-14 ${bgColor} text-gray-50  overflow-hidden rounded-t-2xl `}
        >
          {props.titre}
        </h2>
        <div className="bg-white w-full flex flex-col items-center text-lg rounded-b-2xl tablet:text-base">
          <div
            id="offer-container"
            className="flex pb-4 pl-12 pr-4 tablet:px-0 w-full tablet:w-[230px] flex-col items-start"
          >
            <div className="w-full flex justify-center h-14 mt-4">
              {props.tarif !== "" ? (
                <h2
                  id="offer-price"
                  className={`font-semibold text-6xl tablet:text-4xl tracking-wide ${txtColor}`}
                >
                  {props.tarif}
                  <span className="text-3xl tablet:text-base">€ /mois</span>
                </h2>
              ) : (
                ""
              )}
            </div>
            <div className="w-2/3 mx-auto h-[1px] bg-gray-200 mb-6 mt-4 tablet:mt-0"></div>

            <div className="grid grid-cols-[5fr_2fr] tablet:grid-cols-[155px_auto]  w-full gap-2">
              <PricingItemText
                text="Utilisateurs actifs"
                infoBulletText="Un utilisateur actif est un utilisateur s'étant connecté à Biwee au moins une fois dans le mois. Un même utilisateur actif peut se connecter autant de fois qu'il le souhaite à Biwee. Vous pouvez créer un nombre illimité d'utilisateurs. "
              />
              <span className="flex justify-center tablet:-mr-6 font-semibold  text-2xl tablet:text-lg ">
                {props.utilisateurs}
              </span>
              <PricingItemText text="Designers" />
              <span className="flex justify-center tablet:-mr-6 font-semibold text-2xl tablet:text-lg ">
                {props.designers}
              </span>
              <PricingItemText
                text="Cache intelligent"
                infoBulletText="Système de cache intelligent permettant d'obtenir des temps chargement de tableaux de bord record. Ce système va essayer de pré-charger vos données avant l’ouverture du tableau de bord."
              />
              <span className="flex mt-1 justify-center tablet:-mr-6 font-semibold text-base tablet:text-xs">
                {props.cacheIntelligent}
              </span>
              <PricingItemText
                text="Puissance"
                infoBulletText="Vous avez accès à une puissance de calcul différente en fonction de l’offre choisie. Celle-ci a été dimensionnée en fonction d’une utilisation classique et des limites d’utilisateurs de chaque offre."
              />
              <span className="flex justify-center tablet:-mr-6 font-semibold text-2xl tablet:text-lg ">
                {props.puissance}
              </span>
            </div>
            {/* <div className="w-full flex justify-center">
              <button
                id="offer-subscribe-btn"
                className="btn btn-sm btn-outline m-6"
              >
                Souscrire
              </button>
            </div> */}
            <div
              id="offer-features"
              className="mt-5 pb-3 text-2xl tablet:text-lg "
            >
              <h2 className="font-semibold mb-1">Fonctionalités :</h2>
              <ul className="text-xl tablet:text-sm">
                {props.fonctionalites.map((fonctionalite) => (
                  <li key={nanoid()} className="flex items-start gap-1 -ml-1">
                    <span className="w-4 mt-1 tablet:mt-0.5">
                      <CgCheck size={16} color="green" />
                    </span>
                    <span>{fonctionalite}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
